import React, { useState } from 'react';
import './SideMenu.css';
import { Link } from 'react-router-dom';

const SideMenu = ({ services, onServiceClick }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`side-menu ${menuOpen ? 'open' : ''}`}>
      <ul className="service-list">
        {services.map((service) => (
          <Link to={`/services/${service.serviceNam}-Photographers`} className='service-link'>
          <li key={service.id} className="service-item" onClick={() => onServiceClick(service.id)}>
           {service.serviceNameWithSpace}
          </li>
          </Link> 
        ))}
      </ul>
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default SideMenu;
