import React, { useState, useEffect } from "react";
import "./PopupAd.css"; 

const OfferCard = ({ handleOpenShutter }) => {
  return (
      <div className="offer-card">
        <div className="offer-content">
          <div className="image-section">
            <img src="/images/Banner, Popup, Recommendation Images\Photoshoot Popup form.webp" alt="Photoshoot Offers" className="offer-image" />
          </div>
          <div className="text-section">
            <h2>Limited-Time Offer: Get 40% OFF</h2>
            <p>Sign up and receive a 40% discount on your first pre-wedding or bridal photoshoot booking. Let us capture your special moments in a unique and artistic way.</p>
            <form className="offer-form">
              {/* Ensure this only opens the form and doesn't toggle the shutter */}
              <button className="submit-btn" type="button" onClick={handleOpenShutter}>Get Offer!</button>
            </form>
          </div>
        </div>
      </div>
  );
};

export default OfferCard;
