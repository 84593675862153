import { useEffect,useState,useContext} from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from "../UserContext";
import { useNavigate } from 'react-router-dom';
import './editInvoice.css'
const EditInvoice = ()=>{
    const {setUserInfo,userInfo} = useContext(UserContext);
    const navigate = useNavigate();
    const [alldetails, setAlldetails] = useState({
        phone: [],
        venue: [],
        date: [],
        totalprice: [],
        // bundle: [],
        occasion: [],
      });
      const [alldetails1, setAlldetails1] = useState({
        phone1: [],
        venue1: [],
        date1: [],
        totalprice1: [],
        // bundle: [],
        occasion1: [],
      });
      useEffect(() => {
        fetch('/editinvoice1', {
          credentials: 'include',
        })
          .then(response => response.json())
          .then(data => {
            // console.log(data)
            setAlldetails({
              phone: data[0],
              venue: data[1],
              date: data[2],
              totalprice: data[3],
            //   bundle: data[4],
              occasion: data[5],
            });
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
      useEffect(() => {
        fetch('/editinvoice2', {
          credentials: 'include',
        })
          .then(response => response.json())
          .then(data => {
            // console.log(data)
            setAlldetails1({
              phone1: data[0],
              venue1: data[1],
              date1: data[2],
              totalprice1: data[3],
              // bundle: data[4],
              occasion1: data[5],
            });
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
      const phone = userInfo?.phone;

      function handlepayment(){
        navigate('/payment');
      }

    return(
        <div className='main-invoice-div1'>
          {!phone &&(
            <div style={{ width: "100vw", height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <h1>Your Cart is Empty</h1>
            </div>
        )}
            {phone &&(
            <table className='payment-table1'>
              <thead>
                <tr>
                  <th>Occasion</th>
                  <th>Phone</th>
                  <th>Venue</th>
                  <th>Date</th>
                  <th>Total Price</th>
                  <th>Bundle</th>
                  <th>Payment Update</th>
                </tr>
              </thead>
              <tbody>
              {alldetails.phone.map((phone, index) => (
            <tr key={index}>
              <td>{alldetails.occasion[index].slice(8)}</td>
              <td>{phone}</td>
              <td>{alldetails.venue[index]}</td>
              <td>{alldetails.date[index]}</td>
              <td>{alldetails.totalprice[index]}</td>
              {/* <td>
                <ul>
                  {alldetails.bundle[index].map(bundleItem => (
                    <li key={bundleItem.id}>
                      Role: {bundleItem.role}, Price: {bundleItem.price}, Discount: {bundleItem.discount}%
                    </li>
                  ))}
                </ul>
              </td> */}
              <td>bundle</td>
              <td><button className='submit1' onClick={handlepayment}>Pay Now</button></td>
            </tr>
          ))}
          {alldetails1.phone1.map((phone1, index) => (
            <tr key={index}>
              <td>{alldetails1.occasion1[index].slice(8)}</td>
              <td>{phone1}</td>
              <td>{alldetails1.venue1[index]}</td>
              <td>{alldetails1.date1[index]}</td>
              <td>{alldetails1.totalprice1[index]}</td>
              {/* <td>
                <ul>
                  {alldetails.bundle[index].map(bundleItem => (
                    <li >
                      Role: {alldetails.roles}, Price: {alldetails.price}, Discount: {alldetails.discount}%
                    </li>
                  ))}
                </ul>
              </td> */}
              <td>bundle</td>
              <td><button onClick={handlepayment} className='submit1'>Pay Now</button></td>
            </tr>
          ))}
        </tbody>
      </table>)}
        </div>
    )
}

export default EditInvoice;