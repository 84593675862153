import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter, faLinkedin, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './ContactPage.css';

const ContactPage = () => {
  const whatsappURL = "https://api.whatsapp.com/send?phone=+919415597493";
  return (
    <div className="contact-page">
      <h2>Contact Us</h2>
      <div className="social-media-links">
        <a href="https://www.facebook.com/fotoshoot.in" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="https://instagram.com/stories/fotoshoot.in/3131948075324957763?utm_source=ig_story_item_share&igshid=MTc4MmM1YmI2Ng==" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://twitter.com/fotoshootin" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://www.linkedin.com/company/fotoshoot-in/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href="https://youtube.com/@fotoshoot1664" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
      <div className="contact-form">
        <h3>Send Us an Email</h3>
        <p>Click the button below to open your email client and send us an email:</p>
        <a href="mailto:support@fotoshoot.in" className="email-button">Send Email</a>
      </div>
      <div className="phone-number">
          <FontAwesomeIcon icon={faPhone} />
          <a href="tel:+919415597493" style={{marginBottom:0,textDecoration:"none",color:"#ac35b3"}}>9415597493</a>
        </div>
        <div style={{color:"#ac35b3"}}>
          <p style={{textAlign:"center",fontSize:"24px",color:"black"}}>Office Address</p>
          <p>3/138, Vidhayak Puram, Patrakar Puram, Vinay Khand 3, Gomti Nagar, Lucknow, Uttar Pradesh 226010</p>
          <p>24/339, Mahanagar, Lucknow, Uttar Pradesh 226006</p>
          <p>3-49/12 R.D.S.O. COLONY, Alambagh, Lucknow, Uttar Pradesh 226011</p>
          <p>10/17B, Rana Pratap Marg, Prem Nagar, Hazratganj, Lucknow, Uttar Pradesh 226001</p>
        </div>
    </div>
  );
};

export default ContactPage;
