import { useEffect,useState,useContext} from 'react';
import { UserContext } from "../UserContext";
import './Test.css';

function Testadmin(){
    const {setUserInfo,userInfo} = useContext(UserContext);
    useEffect(() => {
        fetch('/profile', {
          credentials: 'include',
        }).then(response => {
          response.json().then(userInfo => {
            setUserInfo(userInfo);
          });
        });
      },[] );
      const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange1 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload1 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload1", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange2 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload2 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload2", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleFileChange3 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload3 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload3", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleFileChange4 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload4 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload4", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange5 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload5 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload5", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange6 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload6 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload6", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange7 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload7 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload7", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange8 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload8 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload8", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange9 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload9 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload9", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange10 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload10 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload10", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange11 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload11 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload11", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange12 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload12 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload12", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange13 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload13 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload13", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange14 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload14 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload14", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange15 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload15 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload15", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleFileChange16 = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload16 = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await fetch("/upload16", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log("Images uploaded successfully");
      } else {
        console.error("Images upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  
      const phone = userInfo?.phone;
      return(
        <div>
            {phone === '0980987878' ? 
            <div className="form-container">
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange1} />
                <button onClick={handleUpload1}>Upload Images for pre wedding</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange2} />
                <button onClick={handleUpload2}>Upload Images for wedding</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange3} />
                <button onClick={handleUpload3}>Upload Images for baby</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange4} />
                <button onClick={handleUpload4}>Upload Images for newbornbaby</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange5} />
                <button onClick={handleUpload5}>Upload Images for maternity</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange6} />
                <button onClick={handleUpload6}>Upload Images for engagement</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange7} />
                <button onClick={handleUpload7}>Upload Images for portfolio</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange8} />
                <button onClick={handleUpload8}>Upload Images for prebirthday party</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange9} />
                <button onClick={handleUpload9}>Upload Images for office party</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange10} />
                <button onClick={handleUpload10}>Upload Images for haldi</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange11} />
                <button onClick={handleUpload11}>Upload Images for product</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange12} />
                <button onClick={handleUpload12}>Upload Images for reception</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange13} />
                <button onClick={handleUpload13}>Upload Images for Mehandi</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange14} />
                <button onClick={handleUpload14}>Upload Images for Sangeet</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange15} />
                <button onClick={handleUpload15}>Upload Images for birthdaypartyphotos</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange16} />
                <button onClick={handleUpload16}>Upload Images for WeddingAnniversary</button>
            </div>
          </div>: <h1>Not Admin</h1>}
        </div>
      )
}
export default Testadmin;