import './Header.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter, faLinkedin, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useEffect,useContext} from 'react';
import { UserContext } from "../UserContext";
import Login from './Login';

const Header = () => {

  const [showLogin,setShowLogin] = useState(false);
  const handleCloseLogin = () => {
    setShowLogin(false); // Close the login overlay
  };

  const {setUserInfo,userInfo} = useContext(UserContext);
    useEffect(() => {
        fetch('/profile', {
          credentials: 'include',
        }).then(response => {
          response.json().then(userInfo => {
            setUserInfo(userInfo);
          });
        });
      },[] );
    
      function logout() {
        fetch('/logout', {
          credentials: 'include',
          method: 'POST',
        });
        setUserInfo(null);
      }
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMobileMenu((prevState) => !prevState);
  };
  const whatsappURL = "https://api.whatsapp.com/send?phone=+919415597493";

  function handlecart(){
    window.location.href = "/editinvoice";
  }
  return (
    <header>
      <div className="container1">
        <div className='above-header'>
        <div className='contact-info'>
          <a href="tel:+919415597493" style={{marginBottom:0, textDecoration:"none", color:"white"}} aria-label="Call us at +91-9415597493">
            <FontAwesomeIcon icon={faPhone} /> +91-9415597493
          </a>
        </div>
        <div className="social-media-linkss">
          <a href="https://www.facebook.com/fotoshoot.in" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://instagram.com/fotoshoot.in?igshid=NzZhOTFlYzFmZQ==" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://twitter.com/fotoshootin" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Twitter">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.linkedin.com/company/fotoshoot-in/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on LinkedIn">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://youtube.com/@fotoshoot1664" target="_blank" rel="noopener noreferrer" aria-label="Follow us on YouTube">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href={whatsappURL} target="_blank" rel="noopener noreferrer" aria-label="Chat with us on WhatsApp">
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
        </div>
        <nav className={`navbar ${showMobileMenu ? 'active' : ''}`}>
          <Link to="/" className="navbar-logo">
            <img src='/fotoshoot-official.png' alt='fotoshoot' style={{height:"50px"}}/>
          </Link>
          <div className={`burger-icon ${showMobileMenu ? 'active' : ''}`} onClick={handleToggleMenu}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <ul className={`navbar-menu ${showMobileMenu ? 'active' : ''}`}>
            <li className='navbar-item'>
              <Link to="/" className="navbar-link" onClick={handleToggleMenu}>
                HOME
              </Link>
            </li>
            <li className='navbar-item'>
              <Link to="/services" className="navbar-link" onClick={handleToggleMenu}>
                SERVICES
              </Link>
            </li>
            <li className='navbar-item'>
              <Link to="/testimonials" className="navbar-link" onClick={handleToggleMenu}>
                TESTIMONIALS
              </Link>
            </li>
            <li className='navbar-item'>
              <Link to="/aboutus" className="navbar-link" onClick={handleToggleMenu}>
                ABOUTUS
              </Link>
            </li>
            <li  className='navbar-item'>
              <Link to="/contact" className="navbar-link" onClick={handleToggleMenu}>
                CONTACT
              </Link>
            </li>
            {/* <div className="navbar-buttons">
            <li  className='navbar-button'>
              <Link to="/tojoinus" className="navbar-link" onClick={handleToggleMenu}>
                Join Us
              </Link>
            </li>
            </div> */}
            <div className="navbar-buttons">
              <li className='navbar-button'>
              {!userInfo?.phone && (
                <div className="dropdown">
                  <Link className="navbar-link">Login</Link>
                  <div className="dropdown-content">
                    <Link className="navbar-Userbtn" onClick={() => setShowLogin(true)}>Login As User</Link>
                    <Link to="/tojoinus" className="navbar-Userbtn" onClick={handleToggleMenu}>Login to JoinUs</Link>
                  </div>
                </div>
              )}
              {userInfo?.phone && (
                <div className="dropdown">
                  {/* <Link className="navbar-link">Profile</Link> */}
                  <img height="30" viewBox="0 0 32 32" width="30" src = "/profile.png" />
                  <div className="dropdown-content">
                      <Link className="navbar-Userbtn" onClick={logout}>Logout</Link>
                      <Link to="/tojoinus" className="navbar-Userbtn" onClick={handleToggleMenu}>Login to JoinUs</Link>
                      <div className="navbar-profile">
                      <button onClick={handlecart} className="navbar-button1">🛒</button>
                    </div>
                  </div>
                </div>
                
              )}
              </li>
            </div>
            {showLogin && <Login onClose={handleCloseLogin}/>}
            </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
