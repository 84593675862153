import { Link } from 'react-router-dom';
import './Bill.css';
import Lottie from 'lottie-react';
import lottie1 from '../../src/assets/lottie1.json';
import { useEffect,useState,useContext} from 'react';

const Bill = ()=>{

  const [alldetails,setalldetails] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/billing', {
          credentials: 'include',
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const userInfo = await response.json();
        setalldetails(userInfo);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  
  console.log(alldetails);

    const current_date = new Date();
    const new_format_result = current_date.toISOString().slice(0,10);

    function handleeditinvoice(){
      window.location.href = "/editinvoice";
    }


return(
    <div className="Invoice-box">
      {loading && <div className="loading-animation">
        <Lottie animationData = {lottie1}
        style={{width:"60%",height:"60%"}}/>
        </div>}
      {!loading && (
        <div>
          <hr className='dash'></hr>
        <p className="heading-invoice">Invoice</p>
        <p className="heading-fotoshoot">Fotoshoot.in</p>
        <p className='invoice-details'>Fotoshoot™<br/>
H-36 Sector g LDA Colony Ashiyana Kanpur Road<br/>
Lucknow<br/>
Lucknow, Uttar Pradesh<br/>
IN 226012<br/>
<strong>State Name:</strong> Uttar Pradesh <strong>Code:</strong> 09
</p>
<div className='invoice-ship-details'>
    <div><strong>Bill To:<br/></strong>{alldetails.phone}</div>
    <div><strong>Ship To:<br/></strong>{alldetails.phone}</div>
    <div className='invoice-number'><strong>INVOICE NO</strong>  SI-0000039<br/>
    <strong>INVOICE DATE</strong> {new_format_result}<br/>
    <strong>DUE DATE</strong> {alldetails.date}
    </div>
</div>
<div className='table-div'>
<table className="flex-table">
  <tbody>
    <tr>
      <th>Name</th>
      <td data-label="Name">{alldetails.name}</td>
    </tr>
    <tr>
      <th>Roles</th>
      <td data-label="Roles">({alldetails.roles})</td>
    </tr>
    <tr>
      <th>Unit Price</th>
      <td data-label="Unit Price">{alldetails.price}</td>
    </tr>
    <tr>
      <th>Discount</th>
      <td data-label="Discount">{alldetails.discount}</td>
    </tr>
    <tr>
      <th>Total Price</th>
      <td data-label="Total Price">{alldetails.totalprice}</td>
    </tr>
  </tbody>
</table>
</div>
<div className='digitalSig'>
    <img src='images/digitalsignature.png' alt='digital signature' style={{height:"50px"}}/>
    </div>
    <div>
      <button onClick={handleeditinvoice} className='editinvoice'>Add to Cart</button>
      <Link to={'/payment'}><button className='submit'>Pay Now</button></Link>
    </div>
        </div>
     )}
    </div>
    // <h2 style={{ width: "100vw", height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>Billing and payments will be provided soon.</h2>
);
}

export default Bill;