import React, { useState } from 'react';
import SideMenu from './SideMenu';
import ServicePhotos from './ServiceComponent';
import services from './Services'; 
import './ServicePage.css';

const ServicesPage = (serv) => {
  console.log(serv);
  const [selectedServiceId, setSelectedServiceId] = useState(serv.serv);

  const handleServiceClick = (serviceId) => {
    setSelectedServiceId(serviceId);
  };

  return (
    <div className="services-page">
      <nav className="mobile-nav">
          <SideMenu services={services} onServiceClick={handleServiceClick}/>
        </nav>
      <div className="main-content">
        <ServicePhotos selectedServiceId={selectedServiceId} services={services} />
      </div>
    </div>
  );
};

export default ServicesPage;
