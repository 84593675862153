import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Carousel.css';

const events = [
  { image: '/images/pricingreception.jpg', event: 'Pre-Wedding' },
  { image: '/Weddinggallery/59.webp', event: 'Engagement' },
  { image: '/Weddinggallery/60.webp', event: 'Haldi' },
  { image: '/Weddinggallery/63.webp', event: 'Wedding' },
  { image: '/Weddinggallery/61.webp', event: 'Mehandi' },
  { image: '/Weddinggallery/62.webp', event: 'Sangeet' },
  { image: '/Weddinggallery/64.webp', event: 'Wedding' },
  { image: '/Weddinggallery/65.webp', event: 'Reception' },
];

const CoverflowCarousel = () => {

  return (
      <Swiper
        modules={[Autoplay,EffectCoverflow, Navigation, Pagination]}
        effect="coverflow"
        autoplay={{
          delay: 2750,
          disableOnInteraction: false,
        }}
        spaceBetween={10}
        slidesPerView={2}
        centeredSlides={true}
        loop={true}
        loopAdditionalSlides={2}
        // pagination={{ clickable: true }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
        }}
      >
        {events.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content">
              <div className="overlay">
                <p className='overlay-text'>{item.event}</p>
              </div>
              <img
                src={item.image}
                alt={`Slide ${index + 1}`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
  );
};

export default CoverflowCarousel;
