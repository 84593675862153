import { useEffect,useState,useContext} from 'react';
import { UserContext } from "../UserContext";
import './Test.css';

function AdminPortfolio(){
    const {setUserInfo,userInfo} = useContext(UserContext);
    useEffect(() => {
        fetch('/profile', {
          credentials: 'include',
        }).then(response => {
          response.json().then(userInfo => {
            setUserInfo(userInfo);
          });
        });
      },[] );
      const [selectedFiles, setSelectedFiles] = useState([]);

      const handleFileChange1 = (event) => {
        setSelectedFiles([...event.target.files]);
      };
    
      const handleUpload1 = async () => {
        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append("images", file);
        });
    
        try {
          const response = await fetch("/uploadportfolio1", {
            method: "POST",
            body: formData,
          });
    
          if (response.status === 200) {
            console.log("Images uploaded successfully");
          } else {
            console.error("Images upload failed");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      };
      const handleFileChange2 = (event) => {
        setSelectedFiles([...event.target.files]);
      };
    
      const handleUpload2 = async () => {
        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append("images", file);
        });
    
        try {
          const response = await fetch("/wedding/events", {
            method: "POST",
            body: formData,
          });
    
          if (response.status === 200) {
            console.log("Images uploaded successfully");
          } else {
            console.error("Images upload failed");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      };
      const phone = userInfo?.phone;
      return(
        <div style={{marginBottom:"100px"}}>
            {phone === '0980987878' ? 
            <div className="form-container">
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange1} />
                <button onClick={handleUpload1}>Upload Images for Portfolio Page</button>
            </div>
            <div className="form-box">
                <input type="file" multiple onChange={handleFileChange2} />
                <button onClick={handleUpload2}>Upload Images for wedding memoryline</button>
            </div>
            </div>: <h1>Not Admin</h1>}
        </div>
      )
}

export default AdminPortfolio;