import React, {useContext, useState,useEffect } from "react";
import { UserContext } from "../UserContext";
import {useLocation } from 'react-router-dom';
import axios from 'axios'; 
import './PricingPreWedding.css';

const PricingHaldi = ()=>{

  const cities = [
    "Agra", "Ayodhya", "bareilly", "Fatehpur-Sikri", "Kanpur", "Lucknow", "Mathura",
    "Prayagraj", "Unnao", "Hardoi", "Varanasi", "Barabanki", "Noida", "Chitrakoot-Uttar-Pradesh", "Jhansi"
  ];
  const [venueQ, setVenueQ] = useState('');

  const {userInfo} = useContext(UserContext);
  const [phone,setPhone] = useState(userInfo.phone);
  const [data,setdata] = useState({});
  const [rolesData, setRolesData] = useState([]);
  const [username,setUsername] = useState();

  const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const receivedData = searchParams.get('data');
    const [venue,date,received] = receivedData.split(','); 

    useEffect(() => {
      if (!venue || !cities.includes(venue)) {
        setVenueQ('Lucknow');
      } else {
        setVenueQ(venue);
      }
    }, [venue, cities]);
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post('/haldicitypricing', { venue: venueQ });
          setdata(response.data);
          setRolesData([
            { id: 1, role: "Candid", price: response.data.candidprice, discount: response.data.candiddiscount, count: 0 },
            { id: 2, role: "Cinematic", price: response.data.cinematicprice, discount: response.data.cinematicdiscount, count: 0 },
            { id: 3, role: "Drone", price: response.data.droneprice, discount: response.data.dronediscount, count: 0 },
            { id: 4, role: "Traditional Photographer", price: response.data.tradphotoprice, discount: response.data.tradphotodiscount, count: 0 },
            { id: 5, role: "Traditional Videos", price: response.data.tradvideoprice, discount: response.data.tradvideodiscount, count: 0 },
          ]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      if (venueQ) {
        fetchData();
      }
    }, [venueQ]);
    
    // console.log(data.candidprice);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('/username', {
            credentials: 'include',
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const userInfo = await response.json();
          setUsername(userInfo.username);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);


      const userselectedRoles = [];

      const [selectedRoles, setSelectedRoles] = useState([]);
      const [roleCounts, setRoleCounts] = useState({});
      const handleCheckboxChange = (event) => {
        const roleId = parseInt(event.target.value);
        const updatedCounts = { ...roleCounts };
        updatedCounts[roleId] = event.target.checked ? (updatedCounts[roleId] || 0) + 1 : 0;
        setRoleCounts(updatedCounts);
    
        const updatedRolesData = rolesData.map((role) =>
          role.id === roleId ? { ...role, count: event.target.checked ? role.count + 1 : role.count - 1 } : role
        );
        setRolesData(updatedRolesData);
    
        if (event.target.checked) {
          setSelectedRoles([...selectedRoles, roleId]);
        } else {
          setSelectedRoles(selectedRoles.filter((id) => id !== roleId));
        }
      };
      const handleIncreaseCount = (roleId) => {
        const updatedRolesData = rolesData.map((role) =>
          role.id === roleId ? { ...role, count: role.count + 1 } : role
        );
        setRolesData(updatedRolesData);
      };
      const handleDecreaseCount = (roleId) => {
        const updatedRolesData = rolesData.map((role) =>
          role.id === roleId
            ? { ...role, count: Math.max(role.count - 1, 0) } 
            : role
        );
        setRolesData(updatedRolesData);
      };
      
      const { totalPrice, totalDiscount, totalUnitPrice } = selectedRoles.reduce(
        (acc, roleId) => {
          const selectedRole = rolesData.find((role) => role.id === roleId);
          const roleCount = selectedRole.count; // Get the count of the selected role
          acc.totalPrice += selectedRole.price * roleCount; // Multiply by count
          acc.totalDiscount += (selectedRole.price * selectedRole.discount * roleCount) / 100;
          acc.totalUnitPrice += selectedRole.price * roleCount; // Multiply by count
          return acc;
        },
        { totalPrice: 0, totalDiscount: 0, totalUnitPrice: 0 }
      );
      useEffect(() => {
        setSelectedRoles([]);
      }, []);



      function userSelectedRoles(){
        for(var i=0;i<selectedRoles.length;i++){
          userselectedRoles.push(rolesData[selectedRoles[i]-1]);
        }
      }
      
      const total = totalUnitPrice-totalDiscount.toFixed(2);

       async function handleservice1(ev){
        ev.preventDefault();
         const response = await fetch('/invoice', {
          method: 'POST',
          body: JSON.stringify({phone,venue,date,userselectedRoles,total,received,username}),
          headers: {'Content-Type':'application/json'},
        });
        if(response.ok){
          window.location.href = '/bill1';
        }
        else{
          console.log(response.status);
        }
    }

    
//Bundle
      const [selectedBundle, setSelectedBundle] = useState();

  const bundlePackages = [
    { id: 1, name: "Gold Bundle", roles: ["Candid ","Cinematic ","Drone ","Traditional Photos ","Traditional Videos "], price: 49999, discount: 40 },
    { id: 2, name: "Silver Bundle", roles: ["Candid ","Cinematic ","Drone "], price: 34999, discount: 45 },
    { id: 3, name: "Bronze Bundle", roles: ["Traditional Photos ","Traditional Videos "], price: 29999, discount: 40 },
  ];

  const handleBundleChange = (bundleId) => {
    const bundle = bundlePackages.find((bundle) => bundle.id === bundleId);
    setSelectedBundle(bundle);
  };

  
  async function handleservice2(ev){
    ev.preventDefault();
    const t = ((selectedBundle.price - (selectedBundle.price * selectedBundle.discount) / 100).toFixed(2));
    console.log(t);
    console.log(selectedBundle);
     const response = await fetch('/invoice1', {
      method: 'POST',
      body: JSON.stringify({phone,venue,date,selectedBundle,t,received,username}),
      headers: {'Content-Type':'application/json'},
    });
    if(response.status === 200){
      window.location.href = '/bill';
    }
    else{
      console.log(response.status);
    }
}


    return(
        <div>
            <h1 style={{textAlign:"center",fontFamily: "Montserrat sans-serif"}}>Haldi</h1>
            {/* <h2>{data.candidprice}</h2> */}
            <p className='subtitle'>select the roles and get the most effective pricing</p>
          <div className="PricingCheckBox">
                  {rolesData.map((roleData) => {
              const isSelected = selectedRoles.includes(roleData.id);

              return (
                <div style={{display:"flex"}} key={roleData.id}>
                  <label className="labell">
                    <input
                      type="checkbox"
                      value={roleData.id}
                      checked={isSelected}
                      onChange={handleCheckboxChange}
                      className="checkbox-input"
                    />
                    {roleData.role}
                    <p className="custom-price1">
                    Unit Price: {roleData.price}   Custom Discount: {roleData.discount}% 
                    <p className="custom-price1">Count: {roleData.count}</p>
                    </p>
                  </label>
                    <div>
                    <button onClick={() => handleIncreaseCount(roleData.id)} className="custom-button">+1</button>
                    </div>
                    <div>
                    <button
                    className="custom-button"
                    onClick={() => handleDecreaseCount(roleData.id)}
                    disabled={roleData.count === 0}
                  >
                    -1
                  </button>
                    </div>
                </div>
                  );
                })}
                <hr />
                  <form onSubmit={handleservice1}>
                    <div>
                      <h3>Total Discount: {totalDiscount && (totalDiscount.toFixed(2)/totalUnitPrice*100).toFixed(2)}%</h3>
                      <h3>Total Unit Price: {totalUnitPrice}</h3>
                      <h3>Total Price: {totalUnitPrice-totalDiscount.toFixed(2)}</h3>
                    </div>
                      {totalDiscount !==0 &&<div>
                      <button onClick={userSelectedRoles} className="but1">Proceed</button>
                      </div>}
                  </form>
                </div>
              {/* <div className="bundle-form-container">
                <h2 className="bundle-offer-heading">Bundle Offer</h2>
                <div className="bundle-form">
                  {bundlePackages.map((bundle) => (
                    <div key={bundle.id} className="bundle-item">
                      <label className="bundle-label">
                        <input
                          type="radio"
                          name="bundle"
                          value={bundle.id}
                          checked={selectedBundle && selectedBundle.id === bundle.id}
                          onChange={() => handleBundleChange(bundle.id)}
                          className="bundle-checkbox-input"
                        />
                          {bundle.name} (Roles: {bundle.roles.join(", ")})
                          <br />
                          Total Price: {bundle.price} | Total Discount: {bundle.discount}%
                      </label>
                    </div>
                  ))}
               </div>
              {selectedBundle && (
                <form onSubmit={handleservice2} className="bundle-bill">
                  <h3>Selected Bundle: {selectedBundle.name}</h3>
                  <p>Roles: {selectedBundle.roles.join(", ")}</p>
                  <p>Total Price: {selectedBundle.price}</p>
                  <p>Total Discount: {selectedBundle.discount}%</p>
                  <p>Final Price: {(selectedBundle.price - (selectedBundle.price * selectedBundle.discount) / 100).toFixed(2)}</p>
                  <button className="but1">Proceed</button>
                </form>
              )}
            </div> */}
        </div>
    );
};

export default PricingHaldi;