import { useEffect,useState,useContext} from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from "../UserContext";
import { useNavigate } from 'react-router-dom';
import './InvoiceDetails.css';
const AdminInvoice = ()=>{

  const {setUserInfo,userInfo} = useContext(UserContext);
  useEffect(() => {
      fetch('/profile', {
        credentials: 'include',
      }).then(response => {
        response.json().then(userInfo => {
          setUserInfo(userInfo);
        });
      });
    },[] );
    const navigate = useNavigate();
    const [alldetails, setAlldetails] = useState({
        phone: [],
        venue: [],
        date: [],
        totalprice: [],
        // bundle: [],
        occasion: [],
        username: [],
        id: [],
        created_at: []
      });
      // const [alldetails1, setAlldetails1] = useState({
      //   phone1: [],
      //   venue1: [],
      //   date1: [],
      //   totalprice1: [],
      //   occasion1: [],
      //   username1: [],
      //   id1: [],
      //   created_at1: []
      // });
      useEffect(() => {
        fetch('/admininvoice1', {
          credentials: 'include',
        })
          .then(response => response.json())
          .then(data => {
            // console.log(data)
            setAlldetails({
              phone: data[0],
              venue: data[1],
              date: data[2],
              totalprice: data[3],
            //   bundle: data[4],
              occasion: data[4],
              username: data[5],
              id: data[6],
              created_at: data[7]
            });
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
      // useEffect(() => {
      //   fetch('/admininvoice2', {
      //     credentials: 'include',
      //   })
      //     .then(response => response.json())
      //     .then(data => {
      //       // console.log(data)
      //       setAlldetails1({
      //         phone1: data[0],
      //         venue1: data[1],
      //         date1: data[2],
      //         totalprice1: data[3],
      //         // bundle: data[4],
      //         occasion1: data[4],
      //         username1: data[5],
      //         id1: data[6],
      //         created_at1: data[7]
      //       });
      //     })
      //     .catch(error => {
      //       console.error('Error fetching data:', error);
      //     });
      // }, []);
      const phone = userInfo?.phone;
      const current_date = new Date();
      const new_format_result = current_date.toISOString().slice(0,10);

      function handlepayment(){
        navigate('/payment');
      }

    return(
        <div className='main-invoice-div'>
          {phone !== '0980987878'  &&(
            <div style={{ width: "100vw", height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <h1>Not Admin</h1>
            </div>
        )}
            {phone === '0980987878'  &&(
            <table className='payment-table'>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>username</th>
                <th>Occasion</th>
                  <th>Phone</th>
                  <th>Venue</th>
                  <th>Date</th>
                  <th>Total Price</th>
                  {/* <th>Bundle</th> */}
                  <th>Payment Update</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
              {alldetails.phone.map((phone, index) => (
            <tr key={index}>
              <td>{alldetails.id[index]}</td>
              <td>{alldetails.username[index]}</td>
              <td>{alldetails.occasion[index].slice(8)}</td>
              <td>{phone}</td>
              <td>{alldetails.venue[index]}</td>
              <td>{alldetails.date[index]}</td>
              <td>{alldetails.totalprice[index]}</td>
              {/* <td>
                <ul>
                  {alldetails.bundle[index].map(bundleItem => (
                    <li key={bundleItem.id}>
                      Role: {bundleItem.role}, Price: {bundleItem.price}, Discount: {bundleItem.discount}%
                    </li>
                  ))}
                </ul>
              </td> */}
              {/* <td>bundle</td> */}
              {/* <button className='submit' onClick={handlepayment}>Pay Now</button> */}
              <td>payment update</td>
              {alldetails.created_at[index].slice(0,10) === new_format_result && <td style={{backgroundColor:"cyan"}}>{alldetails.created_at[index].slice(0,10)}</td>}
              {/* <td>{alldetails.created_at[index].slice(0,10)}</td> */}
              {alldetails.created_at[index].slice(0,10) !== new_format_result && <td>{alldetails.created_at[index].slice(0,10)}</td>}
            </tr>
          ))}
          {/* {alldetails1.phone1.map((phone1, index) => (
            <tr key={index}>
              <td>{alldetails1.id1[index]}</td>
              <td>{alldetails1.username1[index]}</td>
              <td>{alldetails1.occasion1[index].slice(8)}</td>
              <td>{phone1}</td>
              <td>{alldetails1.venue1[index]}</td>
              <td>{alldetails1.date1[index]}</td>
              <td>{alldetails1.totalprice1[index]}</td> */}
              {/* <td>
                <ul>
                  {alldetails.bundle[index].map(bundleItem => (
                    <li >
                      Role: {alldetails.roles}, Price: {alldetails.price}, Discount: {alldetails.discount}%
                    </li>
                  ))}
                </ul>
              </td> */}
              {/* <td>bundle</td> */}
              {/* <button onClick={handlepayment} className='submit'>Pay Update</button> */}
              {/* <td>payment update</td> */}
              {/* {alldetails1.created_at1[index].slice(0,10) === new_format_result && <td style={{backgroundColor:"cyan"}}>{alldetails1.created_at1[index].slice(0,10)}</td>}
              {alldetails1.created_at1[index].slice(0,10) !== new_format_result && <td>{alldetails1.created_at1[index].slice(0,10)}</td>} */}
              {/* <td>{alldetails1.created_at1[index].slice(0,10)}</td>
            </tr>
          ))} */}
        </tbody>
      </table>)}
        </div>
    )
}

export default AdminInvoice;