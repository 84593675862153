import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./FrontPage.css";
import { Link } from "react-router-dom";

const Slider = () => {
  const slideRef = useRef(null);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const handleClickNext = () => {
    let items = slideRef.current.querySelectorAll(".item");
    slideRef.current.appendChild(items[0]);
  };

  const handleClickPrev = () => {
    let items = slideRef.current.querySelectorAll(".item");
    slideRef.current.prepend(items[items.length - 1]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleClickNext(); 
    }, 4500); 

    return () => clearInterval(interval); 
  }, []);

  const data = [
    {
      id: 1,
      imgUrl: "/Weddinggallery/5.webp",
      desc: "Eternalize Love – Wedding: Document the Sacred Union and Joyous Moments in Our Wedding Photography",
      name: "Wedding",
      link: 'services/Wedding-Photographers'
    },
    {
      id: 2,
      imgUrl:
        "/Weddinggallery/27.webp",
      desc: "Cherishing Milestones- Anniversary: Capture the Timeless Bond of Your Anniversary with Our Expert Photography Services.",
      name: "Prewedding",
      link: 'services/Pre-Wedding-Photographers'
    },
    {
      id: 3,
      imgUrl:
        "/Weddinggallery/3.webp",
      desc: "Celebrating Moments - Reception: Preserve the Joyous Atmosphere of Your Reception with Our Professional Photographers.",
      name: "Reception",
      link: 'services/Reception-Photographers'
    },
    {
      id: 5,
      imgUrl: "/Weddinggallery/8.webp",
      desc: "Cherishing Milestones- Anniversary: Capture the Timeless Bond of Your Anniversary with Our Expert Photography Services.",
      name: "Anniversary",
      link: 'services/Wedding-Anniversary-Photographers'
    },
    {
      id: 6,
      imgUrl:
        "/Weddinggallery/9.webp",
      desc: "Capturing Commitment - Engagement: Experience the Essence of Love in Our Engagement Photography Sessions.",
      name: "Engagement",
      link: 'services/Engagement-Photographers'
    },
  ];

  return (
    <div className="container13">
      <div className="loadbar" style={{ width: `${loadingProgress}%` }}></div>
      <div id="slide1" ref={slideRef}>
        {data.map((item) => (
          <div
            key={item.id}
            className="item"
            style={{ backgroundImage: `url(${item.imgUrl})` }}
          >
            <div className="content">
              <div className="name">{item.name}</div>
              <div className="des">{item.desc}</div>
              <Link to={item.link}><button>See more</button></Link> 
            </div>
          </div>
        ))}
      </div> 
      <div className="buttons">
        <button id="prev" onClick={handleClickPrev}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button id="next" onClick={handleClickNext}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
};

export default Slider;