import React, { useState } from 'react';
import Testadmin from './Test';
import AdminPortfolio from './AddPortfolioimages';
import AdminInvoice from './InvoiceDetails';
import AdminLatest from './AdminLatest';
import QuotationTable from './NewQuotation';
import AllUserDetail from './AllUserDetail';
import './AdminDashboard.css'

const AdminDashboard = () => {
  const [selectedOption, setSelectedOption] = useState('Dashboard');

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="admin-dashboard">
      <div className="side-panel">
        <ul>
        <li
            onClick={() => handleOptionSelect('userdetails')}
            className={selectedOption === 'userdetails' ? 'active' : ''}
          >
            User Details
          </li>
          <li
            onClick={() => handleOptionSelect('Dashboard')}
            className={selectedOption === 'Dashboard' ? 'active' : ''}
          >
            Services Images
          </li>
          <li
            onClick={() => handleOptionSelect('Users')}
            className={selectedOption === 'Users' ? 'active' : ''}
          >
            Portfolio images
          </li>
          <li
            onClick={() => handleOptionSelect('Invoice_Details')}
            className={selectedOption === 'Invoice_Details' ? 'active' : ''}
          >
            Invoice_Details
          </li>
          <li
            onClick={() => handleOptionSelect('Settings')}
            className={selectedOption === 'Settings' ? 'active' : ''}
          >
            LatestPhotosforEvents
          </li>
          <li
            onClick={() => handleOptionSelect('newquotation')}
            className={selectedOption === 'newquotation' ? 'active' : ''}
          >
            New Quotation
          </li>
        </ul>
      </div>
      <div className="main-page">
        {selectedOption === 'userdetails' && <AllUserDetails />}
        {selectedOption === 'Dashboard' && <DashboardContent />}
        {selectedOption === 'Users' && <UsersContent />}
        {selectedOption === 'Invoice_Details' && <ProductsContent />}
        {selectedOption === 'Settings' && <SettingsContent />}
        {selectedOption === 'newquotation' && <NewQuotations />}
      </div>
    </div>
  );
};
const AllUserDetails = () => (
  <div>
    <h2>User Details</h2>
    <AllUserDetail/>
  </div>
);

const DashboardContent = () => (
  <div>
    <h2>AdminDashboard</h2>
    <Testadmin/>
  </div>
);

const UsersContent = () => (
  <div>
    <h2>AdminPortfolio</h2>
    <AdminPortfolio/>
  </div>
);

const ProductsContent = () => (
  <div>
    <h2>InvoiceUpdate</h2>
    <AdminInvoice/>
  </div>
);

const SettingsContent = () => (
  <div>
    <h2>LatestPhotos</h2>
    <AdminLatest/>
  </div>
);
const NewQuotations = () => (
  <div>
    <h2>New Quoatations</h2>
    <QuotationTable />
  </div>
);

export default AdminDashboard;
