import './Bill.css';
import { useEffect,useState,useContext} from 'react';
import Lottie from 'lottie-react';
import lottie1 from '../../src/assets/lottie1.json';
import {Link} from 'react-router-dom';

const Bill1 = ()=>{

  const [alldetails, setalldetails] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/billing1', {
          credentials: 'include',
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const userInfo = await response.json();
        console.log('userinfo: ',userInfo)
        setalldetails(userInfo);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

    const current_date = new Date();
    const new_format_result = current_date.toISOString().slice(0,10);

    function handleeditinvoice(){
      window.location.href = "/editinvoice";
    }

return(
    <div className="Invoice-box">
      {/* <h1>{alldetails.bundle}</h1> */}
        <>
        <hr className='dash'></hr>
        <p className="heading-invoice">Invoice</p>
        <p className="heading-fotoshoot">Fotoshoot.in</p>
        <p className='invoice-details'>Fotoshoot™<br/>
    H-36 Sector g LDA Colony Ashiyana Kanpur Road<br/>
    Lucknow<br/>
    Lucknow, Uttar Pradesh<br/>
    IN 226012<br/>
    <strong>State Name:</strong> Uttar Pradesh <strong>Code:</strong> 09
    </p>
    <div className='invoice-ship-details'>
        <div><strong>Bill To:<br/></strong>{alldetails.phone}</div>
        <div><strong>Ship To:<br/></strong>{alldetails.phone}</div>
        <div className='invoice-number'><strong>INVOICE NO</strong>  SI-0000039<br/>
        <strong>INVOICE DATE</strong> {new_format_result}<br/>
        <strong>DUE DATE</strong> {alldetails.date}</div>
    </div>
    <div className='table-div'>
    <table className="flex-table">
        <thead>
        <tr>
          <th>Roles</th>
          <th>Unit Price</th>
          <th>Discount</th>
          <th>Count</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        <tr><td>{alldetails.occasion}</td></tr>
        {alldetails.roles && alldetails.roles.map((role, index) => (
                <tr key={index}>
                  <td>{role}</td>
                  <td>{alldetails.prices[index]}</td>
                  <td>{alldetails.discounts[index]}%</td>
                  <td>{alldetails.counts[index]}</td>
                  <td>{(alldetails.prices[index] - (alldetails.prices[index] * alldetails.discounts[index] / 100)) * alldetails.counts[index]}</td>
                </tr>
              ))}
      </tbody>
    </table>
    <table className="flex-table">
      <thead>
        <tr>
        <th>Total-UnitPrice</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{alldetails.totalprice}</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div className='digitalSig'>
    <img src='images/digitalsignature.png' alt='digital signature' style={{height:"50px"}}/>
    </div>
    <div>
      <button onClick={handleeditinvoice} className='editinvoice'>Add to Cart</button>
      <Link to={'/payment'}><button className='submit'>Pay Now</button></Link>
    </div>
    </>
    </div>
);
}

export default Bill1;