import React from 'react';
import { useNavigate } from 'react-router-dom';
import './RenderCities.css'

const RenderCities = ({ selectedService }) => {
    const navigate = useNavigate();
    const citiesInUttarPradesh = [
        "Agra", "Ayodhya", "Bareilly", "Fatehpur-Sikri", "Kanpur", "Lucknow", "Mathura", 
        "Prayagraj", "Unnao", "Hardoi", "Varanasi", "Barabanki", "Noida", 
        "Chitrakoot-Uttar-Pradesh", "Jhansi"
      ];

  return (
    <div className="selected-service-list">
      {citiesInUttarPradesh.map((city, index) => (
        <span key={index} className="city">
          <a href={`/services/${selectedService.serviceNam}-Photographers/${city}`} onClick={() => navigate(`/services/${selectedService.serviceNam}-Photographers/${city}`)}>
            {selectedService.serviceName} in {city}
          </a>
        </span>
      ))}
    </div>
  );
};

export default RenderCities;
