import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import './App.css'; // Ensure this import matches your CSS file path

const Sitemap = () => {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const response = await axios.get('/sitemap.xml');
        const parser = new XMLParser();
        const result = parser.parse(response.data);
        const urls = result.urlset.url.map(url => url.loc);
        setLinks(urls);
      } catch (error) {
        console.error('Error fetching sitemap:', error);
      }
    };

    fetchSitemap();
  }, []);

  return (
    <div className="sitemap-container">
      <h1>Sitemap</h1>
      <ul className="sitemap-list">
        {links.map((link, index) => (
          <li key={index}>
            <a href={link}>{link}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sitemap;
