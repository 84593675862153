import { useEffect, useState, useContext } from 'react';
import { UserContext } from "../UserContext";
import './Test.css';

function AdminLatest(){
    const { setUserInfo, userInfo } = useContext(UserContext);
    const options = ["preweddingphotos", "weddingphotos", "haldiphotos", "receptionphotos", "mehandiphotos", "sangeetphotos", "babyphotos", "newbornbabyphotos", "engagementphotos", "birthdaypartyphotos", "officepartyphotos", "weddinganiversaryphotos", "productphotos", "portfoliophotos", "maternityphotos", "prebirthdaypartyphotos"];

    useEffect(() => {
        fetch('/profile', {
            credentials: 'include',
        }).then(response => {
            response.json().then(userInfo => {
                setUserInfo(userInfo);
            });
        });
    }, [setUserInfo]);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [occasion, setOccasion] = useState('');

    const handleFileChange = (event) => {
        setSelectedFiles([...event.target.files]);
    };

    const handleOccasionChange = (event) => {
        setOccasion(event.target.value);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("images", file);
        });
        formData.append("occasion", occasion);

        try {
            const response = await fetch("/uploadlatestprewedding", {
                method: "POST",
                body: formData,
            });

            if (response.status === 200) {
                console.log("Images uploaded successfully");
            } else {
                console.error("Images upload failed");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const phone = userInfo?.phone;

    return (
        <div style={{ marginBottom: "100px" }}>
            {phone === '0980987878' ? 
                <div className="form-container">
                    <div className="form-box">
                        <input type="file" multiple onChange={handleFileChange} />
                        <select value={occasion} onChange={handleOccasionChange}>
                            <option value="">Select Occasion</option>
                            {options.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                        <button onClick={handleUpload}>Upload Images for latest photos</button>
                    </div>
                </div>
                : <h1>Not Admin</h1>
            }
        </div>
    );
}

export default AdminLatest;
