const services = [
  {
    id: 1,
    serviceName: 'PreWedding',
    serviceNam: 'Pre-Wedding',
    photos: [],
    serviceRoute:"preweddingphotos",
    serviceNameWithSpace: 'Pre Wedding',
    introduction:"Pre-wedding photography goes far beyond a trend to symbolize the celebration of love before the grand day. It captures the rawness and excitement of to-be life partners while going to start a new life together. Choosing the right photographer near me who actually knows the art of capturing those lifetime moments is essential for this special occasion, and Fotoshoot.in is one such premier choice. Fotoshoot.in would add creativity and personal services to your pre-wedding shoot moments and turn them into timeless memories. Let's see the reasons that would make Fotoshoot.in the first preference for couples when they think of pre-wedding photography services near me.",
    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."
  },
  {
    id: 2,
    serviceName: 'Wedding',
    serviceNam: 'Wedding',
    photos: [],
    serviceRoute:"weddingphotos",
    serviceNameWithSpace: 'Wedding',
    introduction:"",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."
  },
  {
    id: 3,
    serviceName: 'Haldi',
    serviceNam: 'Haldi',
    photos: [],
    serviceRoute:"haldiphotos",
    serviceNameWithSpace: 'Haldi',
    introduction:"",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 4,
    serviceName: 'Mehandi',
    serviceNam: 'Mehandi',
    photos: [],
    serviceRoute:"mehandiphotos",
    serviceNameWithSpace: 'Mehandi',
    introduction:"Mehandi is not just a wedding ritual, but a vivid, expressive art form that is used to adorn a bride’s hands and feet by celebrating beauty and love. This tradition encapsulates the celebration that an Indian wedding is, and capturing it, especially through Mehndi wedding photography means capturing a tradition enrobed in joy, beauty, and emotions through the medium of photography. This guide offers a comprehensive perspective into Mehndi wedding photography including what it means, what key components it has, and what makes Fotoshoot.in the go-to solution for clients for whom every smile, embrace and look is a treasure to be remembered for eternity.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  //new
  {
    id: 5,
    serviceName: 'Sangeet',
    serviceNam: 'Sangeet',
    photos: [],
    serviceRoute:"sangeetphotos",
    serviceNameWithSpace: 'Sangeet',
    introduction:`These are musical evenings of songs and fun. Add this up to the mark of pre-wedding functions. They are much more than music and dance; they are showcase of songs & dance with emotions, dance, and timeless moments waiting to be seized. Finding the right Sangeet photographer near me for this event is crucial to hold onto these memories forever.\nExperience how the right photographer near me can turn your Sangeet ceremony into a bundle of cherished memories.`,

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 6,
    serviceName: 'Engagement',
    serviceNam: 'Engagement',
    photos: [],
    serviceRoute:"engagementphotos",
    serviceNameWithSpace: 'Engagement',
    introduction:"This magical time in your engagement will only come once, and you want to ensure that the precious moments are captured by your photographer in a style that truly speaks of your relationship. Whether you want candid snapshots or highly styled and posed types of images, your engagement pictures present a perfect opportunity to celebrate your unique bond and show everyone just how happy both of you are.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 7,
    serviceName: 'Reception',
    serviceNam: 'Reception',
    photos: [],
    serviceRoute:"receptionphotos",
    serviceNameWithSpace: 'Reception',
    introduction:"The objective of reception photography near me is to capture the enjoyment and joy received during special events since it serves a role in keeping treasured memories. The choice of a photographer when it comes to commemorating such big moments might just influence, to a great extent, how said events will be captured and remembered as part of one's history.An experienced photographer at the reception will capture more than just documentation of the happening but the ambiance and feel of the party as well.Choosing a reception photographer near me is embarking on a journey where every smile, dance, and toast is immortalized, right down to the finest detail, with care and creativity. So, that is what we are going to talk about: how you can use reception photography to your best advantage and make your reception a memory of years to come for your event. Understanding Reception Photography",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 8,
    serviceName: 'PreBirthdayParty',
    serviceNam: 'Pre-Birthday-Party',
    photos: [],
    serviceRoute:"prebirthdaypartyphotos",
    serviceNameWithSpace: 'Pre Bithday Party',
    introduction:"Of course! If you are arranging for a pre-birthday photoshoot Near me, it's definitely a fun way to keep the excitement and joy of the upcoming one more year alive. You can arrange a pre-birthday photo shoot near me for yourself, or a friend, or even a family member. This will definitely be an interesting way of capturing the event through your lenses.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  //new
  {
    id: 9,
    serviceName: 'BirthdayShoot',
    serviceNam: 'Birthday-Shoot',
    photos: [],
    serviceRoute:"birthdaypartyphotos",
    serviceNameWithSpace: 'Birthday Shoot',
    introduction:"Make this special day even more special with a birthday photo shoot from Fotoshoot.in! Birthdays come but once every year; it's that one special day. Be it a milestone celebration or a yearly one, all are special, and moments need to be cherished. And here's where Fotoshoot.in is at your service, offering you excellent quality photography services for your birthday celebration.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 10,
    serviceName: 'NewBornBaby',
    serviceNam: 'New-Born-Baby',
    photos: [],
    serviceRoute:"newbornbabyphotos",
    serviceNameWithSpace: 'New Born Baby',
    introduction:"Becoming the parents of a new-born is a moment of great joy and emotional burst for every family. This is to be captured in the best of its possible details, which can only be done by a new-born baby photographer, with his skills and art at maximum display.These photographers are given the awesome opportunity to shoot the early stages in the life of a baby, making lifetime memories of the family. Fotoshoot.in becomes the most preferable choice, standing for unmatched expertise, safety, and quality to preserve lifelong memories.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 11,
    serviceName: 'BabyShoot',
    serviceNam: 'Baby-Shoot',
    photos: [],
    serviceRoute:"babyphotos",
    serviceNameWithSpace: 'Baby Shoot',
    introduction:"It has truly been said that photography is not about faces to shoot, but it is capturing souls; especially, in the case of babies, their innocent expressions at a tender age and involvement of little moments make baby photography really special.This Section tries to help you manage the subtleties of doing baby shoots with insights, tips, and ideas that, hopefully, while doing so, will come in very handy for you to preserve those irreplaceable early memories of your child.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 12,
    serviceName: 'Maternity',
    serviceNam: 'Maternity',
    photos: [],
    serviceRoute:"maternityphotos",
    serviceNameWithSpace: 'Maternity',
    introduction:"Maternity photography is a special and lovely form of photography. It captures one of the most miraculous and life-altering times in a woman's life. It is more than taking photographs; it captures the whole journey, the emotions, and anticipation to take up a new life. This article therefore explores the essence of maternity photography, offering insights on how to choose the right maternity photographer, plan for the photo session, and understand the effect of these precious keepers.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  //new
  {
    id: 13,
    serviceName: 'WeddingAnniversary',
    serviceNam: 'Wedding-Anniversary',
    photos: [],
    serviceRoute:"weddinganiversaryphotos",
    serviceNameWithSpace: 'Wedding Anniversary',
    introduction:"Anniversary wedding is an occasion that marks special milestones in a love partnership journey. Truly, all of them are times of reflection, joy, and being eager for many more years. The happy moments take photographs, capture the moment, and thus preserve the essence of any celebration in sharp memories that will live forever.Specializing in encapsulating the love, the connection, and the unique story of every couple, making sure that in every photo he takes, it radiates with the same emotion and meaning their anniversary does.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 14,
    serviceName: 'CorporateEvent',
    serviceNam: 'Corporate-Event',
    photos: [],
    serviceRoute:"officepartyphotos",
    serviceNameWithSpace: 'Corporate Event',
    introduction:"It plays a very important role, as this is just the genre where it is most necessary, in taking photos during corporate event photography that reflects the essence and dynamics of the gathering. A professional corporate event photographer doesn't just take pictures, but he captures the spirit, networking moments, and key highlights pinpointing the success of the event.Thus, knowing exactly what makes this area so special can help businesses assure the fact that their event's photographic content not only adds value to their brand but also helps to commemorate the event itself as another way to add valuable content. ",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 15,
    serviceName: 'Portfolio',
    serviceNam: 'Portfolio',
    photos: [],
    serviceRoute:"portfoliophotos",
    serviceNameWithSpace: 'Portfolio',
    introduction:"The portfolio photographer embarks on an exciting journey that merges artistry with personal expression. In photography, a portfolio is to a photographer what a curriculum vitae is to a professional. It represents a selection of very best images. It's more than a mere collection of images; it's also a testament to the photographer's skills, vision, and hard work.Whether a professional giant in the field or an emerging enthusiast, these quintessential essentials of portfolio photography are needed in a quest to finally capture the sight of clients, galleries, or collaborators.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
  {
    id: 16,
    serviceName: 'Product',
    serviceNam: 'Product',
    photos: [],
    serviceRoute:"productphotos",
    serviceNameWithSpace: 'Product',
    introduction:"Product photography is not just about capturing images; it's an art that plays a crucial role in the world of commerce.The product photographer should not only be conversant with all the technicalities of photography but also should be detail-oriented and have a creative brain to be able to present the same in the most appealing manner possible.",

    info:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id ornare arcu odio ut sem. Ornare suspendisse sed nisi lacus sed. Convallis convallis tellus id interdum velit. Semper risus in hendrerit gravida rutrum quisque non. Et pharetra pharetra massa massa ultricies mi quis. Sagittis purus sit amet volutpat consequat mauris nunc. Quis commodo odio aenean sed adipiscing diam donec adipiscing. Tellus at urna condimentum mattis pellentesque id. Viverra maecenas accumsan lacus vel facilisis."

  },
];

export default services;
