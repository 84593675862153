// ImageGallery.js
import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './ImageGallery.css';

const ImageGallery = ({ images }) => {
  return (
    <div className="responsive-image-gallery">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 3 }}>
        <Masonry gutter='15px'>
          {images.map((image, index) => (
            <div key={index} className="image-container">
              <LazyLoadImage src={image} loading='lazy' alt={`Image ${index}`} className="gallery-image fade-in" />
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default ImageGallery;
