import React, { useState, useEffect , useContext} from 'react';
import { UserContext } from "../UserContext";
import axios from 'axios';

const AllUserDetail = () => {
  const [users, setUsers] = useState([]);
  const {setUserInfo,userInfo} = useContext(UserContext);
    useEffect(() => {
        fetch('/profile', {
          credentials: 'include',
        }).then(response => {
          response.json().then(userInfo => {
            setUserInfo(userInfo);
          });
        });
      },[] );


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/getallusers');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);
  const phone = userInfo?.phone;
  const current_date = new Date();
  const new_format_result = current_date.toISOString().slice(0,10);

  return (
    <div>
      {phone !== '0980987878'  &&(
            <div style={{ width: "100vw", height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <h1>Not Admin</h1>
            </div>
        )}
        {phone === '0980987878'  &&(
          <>
      <h2>All User Details</h2>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Phone</th>
            <th>date</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              {user.created_at.slice(0,10) === new_format_result && <td style={{backgroundColor:"cyan"}}>{user.created_at.slice(0,10)}</td>}
              {user.created_at.slice(0,10) !== new_format_result && <td>{user.created_at.slice(0,10)}</td>}
            </tr>
          ))}
        </tbody>
      </table>
      </>)}
    </div>
  );
};

export default AllUserDetail;
