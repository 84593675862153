import React, { useState, useContext } from 'react';
import { UserContext } from "../../UserContext";
import { useNavigate } from 'react-router-dom';
import './GetInTouch.css'; // Import your CSS file

const Serviceswithdate = ({ serviceType, cityName }) => {
    const { setUserInfo, userInfo } = useContext(UserContext);
    const [showLogin, setShowLogin] = useState(false);
    const [date, setDate] = useState("DD/MM/YYYY");
    const navigate = useNavigate();
    const [dataExists, setDataExists] = useState(false);
    const currentDate = new Date().toISOString().split('T')[0];
    const phone = userInfo?.phone;

    async function handlealready(ev) {
        ev.preventDefault();
        console.log(date, serviceType, cityName);
        if (!phone) {
            alert("Please login to Checkout the best Prices");
            setShowLogin(true);
            return;
        }
        try {
            const response = await fetch('/venuedate1', {
                method: 'POST',
                body: JSON.stringify({ cityName, date, serviceType }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });
            const data = await response.json();
            console.log(data);
            if (data.length === 0) {
                setDataExists(false);
                navigate(`${'/services/' + serviceType + '-Photographers/' + cityName + '/pricing'}?data=${encodeURIComponent([cityName, date, '/pricing' + serviceType])}`);
            }
            if (data[0].billtype === "ok") {
                setDataExists(true);
                navigate('/bill1');
            } else if (data[0].billtype === "notok") {
                setDataExists(true);
                navigate('/bill');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <div className="cities-container">
            <form onSubmit={handlealready} className='city-input-container'>
            <h2>Choose the desired date for your occasion</h2>
                <div className="custom-form-group">
                    <input type="date" value={date} aria-label="Select a Date" onChange={(e) => setDate(e.target.value)} required min={currentDate} />
                </div>
                <button type='submit' className="checkout-button1">Submit</button>
            </form>
        </div>
    );
};

export default Serviceswithdate;
