import React, { useState, useEffect } from 'react';
import './Banner.css';
import { useNavigate } from 'react-router-dom';

const Banner = ({ cities, serviceName, serviceNameWithSpace }) => {
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState('');
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/services/${serviceName}/${selectedCity}`);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getBannerImage = (name) => {
    const images = {
      PreWedding: ['/images/Banner, Popup, Recommendation Images/Best_Pre_Wedding_Photographers.webp', '/images/Banner, Popup, Recommendation Images/Best_Pre_Wedding_Photography.webp'],
      Wedding: ['/images/pricingweddinghor.jpg', '/images/pricingwedding.jpg'],
      Haldi: ['/images/pricinghaldihor.jpg', '/images/pricinghaldiver.jpg'],
      Mehandi: ['/images/pricingmehandi.jpg', '/images/pricingmehandiver.jpg'],
      Sangeet: ['/images/pricingsangeethor.jpg', '/images/pricingsangeetver.jpg'],
      Engagement: ['/images/pricingengagementhor.jpg', '/images/pricingengagementver.jpg'],
      Reception: ['/images/pricingreceptionhor.jpg', '/images/pricingreception.jpg'],
      PreBirthdayParty: ['/freelance.webp', '/freelance.webp'],
      BirthdayShoot: ['/images/pricingbirthday.jpg', '/images/pricingbirthdayver.jpg'],
      NewBornBaby: ['/images/pricingnewbornbabyhor.jpg', '/images/pricingnewbornbabyver.jpg'],
      BabyShoot: ['/images/pricingbaby.jpg', '/images/pricingbabyver.jpg'],
      Maternity: ['/images/pricingmaternity.jpg', '/images/pricingmaternity.jpg'],
      WeddingAnniversary: ['/images/pricinganniversary.jpg', '/images/pricinganniversary.jpg'],
      CorporateEvent: ['/images/pricingcorporatehor.jpg', '/images/pricingcorporatever.jpg'],
      Portfolio: ['/images/pricingportfolio.jpg', '/images/pricingportfoliover.jpg'],
      Product: ['/images/pricingproduct.jpg', '/images/pricingproductver.jpg']
    };
    return images[name] || ['', ''];
  };

  const [horizontalImg, verticalImg] = getBannerImage(serviceName);
  const selectedImage = screenSize.width > 767 ? horizontalImg : verticalImg;

  return (
    <div className="banner-container">
      <div className="banner-right">
        <h1 className="banner-catchy-line">{serviceNameWithSpace}<br /> Photography</h1>
        <div className='banner-right-form'></div>
      </div>
      <div className={`banner-left banner-left-image ${screenSize.width <= 768 ? 'mobile' : ''}`}>
        <img
          src={selectedImage} // Low-resolution placeholder image
          data-src={selectedImage} // High-resolution image
          alt="Best_Pre_Wedding_Photographers in India"
          className="banner-image lazyload blur-up"
          loading="lazy"
          width="600"
          height="400"
        />
      </div>
    </div>
  );
};

export default Banner;
