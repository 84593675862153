import { useState} from 'react';
import { useLocation} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import './Login.css';

const VenueDate = ()=>{
  const cities = [
    "Agra",
    "Ayodhya",
    "bareilly",
    "Fatehpur Sikri",
    "Kanpur",
    "Lucknow",
    "Mathura",
    "Prayagraj",
    "Unnao",
    "Hardoi",
    "Varanasi",
    "Barabanki",
    "Noida",
    "Chitrakoot Uttar Pradesh",
    "Jhansi",
  ]; 

  const sortedCities = cities.slice().sort(); 

  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const navigate = useNavigate();
  const [dataExists, setDataExists] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const receivedData = searchParams.get('data');
    // console.log(receivedData);

    async function handlealready(ev){
      ev.preventDefault();
      try{
         const response = await fetch('/venuedate', {
          method: 'POST',
          body: JSON.stringify({city,date,receivedData}),
          headers: {'Content-Type':'application/json'},
          credentials: 'include',
        });
        const data = await response.json();
        if(data.length === 0){
          setDataExists(false);
          navigate(`${receivedData}?data=${encodeURIComponent([city,date,receivedData])}`); 
        }
        if (data[0].billtype === "ok" ) {
          setDataExists(true);
          navigate(`${'/bill1'}`);
        } 
        else if(data[0].billtype === "notok"){
          setDataExists(true);
          navigate(`${'/bill'}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    const currentDate = new Date().toISOString().split('T')[0];

    return(
        <form onSubmit={handlealready} className="login-form" >
        <h2 style={{textAlign:"center"}} className="head">Venue and Date</h2>
        <div className="custom-form-group">
        <select className="compact-form-input"  value={city} onChange={(e) => setCity(e.target.value)} required>
          <option value="" disabled>Select a City</option>
          {sortedCities.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
        </div>
        <div className="custom-form-group">
        <input className="form-input1" type="date" value={date} onChange={(e) => setDate(e.target.value)} required min={currentDate}/>
        </div>
          <button className="but2">Submit</button>
      </form>
    );
}

export default VenueDate;