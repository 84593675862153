import { Link,Navigate,useLocation } from 'react-router-dom';
import './Register.css'
import { useState } from 'react';

export default function Signup(){
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        password: '',
      });
      // const [redirect,setRedirect] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function register(ev) {
    ev.preventDefault();
    const response = await fetch('/register', {
      method: 'POST',
      body: JSON.stringify({formData}),
      headers: {'Content-Type':'application/json'},
    });
    if (response.status === 200) {
      alert('registration successful');
      // setRedirect(true);
    } else {
      alert('registration failed');
    }
  }
  // if(redirect){
  //   return(<Navigate to={`/login?data=${encodeURIComponent([service[0],service[1]])}`}/>);
  // }

    return(
        <div className="signup-container">
        <h2 className="signup-heading">New Account</h2>
        <form onSubmit={register}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
            className="signup-input"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="signup-input"
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            className="signup-input"
            />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          className="signup-input"
        />
        <button type="submit" className="signup-btn">Sign Up</button>
      </form>
      <p className="login-link">Already have an account? <span>Login</span></p>
    </div>
    );
}